<template>
    <CModal
      title="Convertir a Vendedor"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                Este usuario tiene el rol de <b>supervisor</b>, si deseas continuar este cambiará su rol a <b>vendedor</b> y sus relaciones y permisos actuales serán eliminados.
                <br /><br />
                <b>NOTA: </b> Todas los registros que esten relacionados a este supervisor serán eliminados, y todos los avances que tenga en concursos activos serán eliminados.
                <br /><br />
                <b style="float: right;">¿Estás seguro que deseas continuar?</b>
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="downgrade" :color="color">Continuar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../services/suppliers';
import managers_ws from '../../services/managers';
import cedis_ws from '../../services/cedis';
import store from '../../store'

export default {
  name: 'DowngradeModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      color: "success",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      link: '',
      item: false,
      managers: [],
      managersOptions: [],
      cedisOptions:[]
    }
  },
  mounted: async function() {

  },
  methods: {
    downgradeModal (supplier_id) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nuevo Vendedor';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: supplier_id,
      }
    },
    downgrade () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("downgrade", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    redirectLink(link){
      window.location = link;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
    }
  },
}
</script>