import axios from 'axios'

export default {
    get: () => axios.get('managers'),
    store: (data) => axios.post('managers/store', data),
    update: (data) => axios.post('managers/update', data),
    downgrade: (data) => axios.post('managers/downgrade', data),
    delete: (id) => axios.post('managers/delete', id),
    import: (data) => axios.post('managers/import', data),
    generateLink: (data) => axios.post('managers/generate-link', data),
    getByChallenge: (challenge_id) => axios.get('challenges/'+challenge_id+'/managers'),
}